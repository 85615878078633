import * as React from "react"
import { Frame, addPropertyControls, ControlType } from "framer"

export function LazyImage({ src, alt }) {
    const altText = alt || "Lazy loaded image"

    return (
        <Frame background={null} style={{ width: "100%", height: "100%" }}>
            <img
                src={src}
                loading="lazy"
                alt={altText}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
        </Frame>
    )
}

LazyImage.defaultProps = {
    src: "",
    alt: "Lazy loaded image",
}

addPropertyControls(LazyImage, {
    src: {
        title: "Image",
        type: ControlType.Image,
    },
    alt: {
        title: "Alt Text",
        type: ControlType.String,
    },
})
